import React, { useState } from 'react';
import { Navbar } from './components/Navbar';
import { Hero } from './components/Hero';
import { NewsFeed } from './components/NewsFeed';
import { TrendingBassists } from './components/TrendingBassists';
import { GearDeals } from './components/GearDeals';
import { Footer } from './components/Footer';
import { BassQuiz } from './components/BassQuiz';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';
import { AffiliateDisclosure } from './pages/AffiliateDisclosure';

export const App: React.FC = () => {
  const [activeSection, setActiveSection] = useState('home');

  return (
    <div className="min-h-screen bg-background text-text">
      <Navbar activeSection={activeSection} setActiveSection={setActiveSection} />
      
      <main className="container mx-auto px-4 py-8">
        {activeSection === 'home' && (
          <>
            <Hero />
            <div className="mt-16">
              <NewsFeed />
            </div>
            <div className="mt-16">
              <TrendingBassists />
            </div>
            <div className="mt-16">
              <GearDeals />
            </div>
          </>
        )}
        {activeSection === 'news' && <NewsFeed />}
        {activeSection === 'trending' && <TrendingBassists />}
        {activeSection === 'gear' && <GearDeals />}
        {activeSection === 'quiz' && <BassQuiz />}
        {activeSection === 'privacy' && <PrivacyPolicy />}
        {activeSection === 'terms' && <TermsOfService />}
        {activeSection === 'affiliate' && <AffiliateDisclosure />}
      </main>

      <Footer />
    </div>
  );
};

export default App;