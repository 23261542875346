import React from 'react';
import { Guitar } from 'lucide-react';
import QuizWidget from './QuizWidget';

export const Hero: React.FC = () => {
  return (
    <div className="grid gap-8 md:grid-cols-2">
      <div className="relative bg-primary rounded-xl overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-primary to-primary-dark opacity-90"></div>
        <div className="relative px-8 py-16">
          <div className="max-w-xl">
            <h1 className="text-4xl sm:text-5xl font-bold text-white mb-6">
              Welcome to BassHub
            </h1>
            <p className="text-xl text-white/90 mb-8">
              Your ultimate destination for bass guitar news, trending artists, and gear reviews.
            </p>
            <button className="bg-white/10 hover:bg-white/20 text-white font-semibold px-6 py-3 rounded-lg transition-colors">
              Browse Gear
            </button>
          </div>
        </div>
        <Guitar className="absolute right-8 bottom-8 w-24 h-24 text-white/20" />
      </div>

      <div className="bg-white rounded-xl shadow-md p-6">
        <QuizWidget />
      </div>
    </div>
  );
};