import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PrivacyPolicy: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy - BassHub</title>
        <meta name="description" content="BassHub's Privacy Policy - Learn how we protect your privacy and handle information." />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="prose prose-lg">
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          
          <p className="text-sm text-gray-600 mb-6">Effective Date: November 11, 2024</p>

          <div className="space-y-6">
            <section>
              <h2 className="text-2xl font-semibold">1. Introduction</h2>
              <p>Welcome to BassHub ("we," "our," or "us"). We are committed to respecting your privacy. This Privacy Policy outlines how we handle information when you visit our website, [yourwebsite.com] (the "Site"). By accessing or using the Site, you agree to the terms of this Privacy Policy. If you do not agree, please refrain from using the Site.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">2. Information We Collect</h2>
              <p>We do not collect personal data such as names, email addresses, or contact information. However, we may collect non-personal information automatically, including:</p>
              <p>Log Data: Information such as your IP address, browser type, operating system, referring URLs, and pages viewed during your visit.</p>
              <p>Cookies: Small data files stored on your device to enhance user experience. You can control cookie preferences through your browser settings.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">3. Use of Information</h2>
              <p>The non-personal information collected is used to:</p>
              <p>Analyze and improve the functionality and content of the Site.</p>
              <p>Monitor and understand usage patterns to enhance user experience.</p>
              <p>Ensure the Site operates efficiently and effectively.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">4. Third-Party Services</h2>
              <p>We may use third-party services, such as analytics providers, that collect, monitor, and analyze non-personal information to help us improve our Site. These third parties have their own privacy policies addressing how they use such information.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">5. Security</h2>
              <p>We implement reasonable measures to protect the non-personal information collected. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">6. Changes to This Privacy Policy</h2>
              <p>We may update this Privacy Policy periodically. Changes will be posted on this page with an updated effective date. We encourage you to review this policy regularly.</p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};