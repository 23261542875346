import { useStore } from '../store/useStore';

interface AffiliateLink {
  originalUrl: string;
  affiliateUrl: string;
  commission: number;
  provider: string;
}

const AFFILIATE_MAPPINGS: Record<string, AffiliateLink> = {
  'sweetwater.com': {
    originalUrl: 'sweetwater.com',
    affiliateUrl: 'sweetwater.com/?affiliate=basshub',
    commission: 0.08,
    provider: 'Sweetwater'
  },
  'thomann.de': {
    originalUrl: 'thomann.de',
    affiliateUrl: 'thomann.de/partner/basshub',
    commission: 0.06,
    provider: 'Thomann'
  }
};

export function generateAffiliateLink(url: string): string {
  const domain = new URL(url).hostname.replace('www.', '');
  const affiliate = AFFILIATE_MAPPINGS[domain];
  
  if (!affiliate) return url;
  
  return url.replace(affiliate.originalUrl, affiliate.affiliateUrl);
}

export function trackAffiliateClick(url: string) {
  const domain = new URL(url).hostname.replace('www.', '');
  const affiliate = AFFILIATE_MAPPINGS[domain];
  
  if (!affiliate) return;
  
  // Track click for analytics
  const event = {
    type: 'affiliate_click',
    provider: affiliate.provider,
    url,
    timestamp: new Date().toISOString()
  };
  
  // Store in local analytics
  const clicks = JSON.parse(localStorage.getItem('affiliate_clicks') || '[]');
  clicks.push(event);
  localStorage.setItem('affiliate_clicks', JSON.stringify(clicks));
}