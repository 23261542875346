import React from 'react';
import { Helmet } from 'react-helmet-async';

export const TermsOfService: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service - BassHub</title>
        <meta name="description" content="BassHub's Terms of Service - Read our terms and conditions for using the platform." />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="prose prose-lg">
          <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
          
          <p className="text-sm text-gray-600 mb-6">Effective Date: November 11, 2024</p>

          <div className="space-y-6">
            <section>
              <h2 className="text-2xl font-semibold">1. Acceptance of Terms</h2>
              <p>By accessing and using the BassHub website ("Site"), you agree to comply with these Terms of Service. If you do not agree, please do not use the Site.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">2. Description of Service</h2>
              <p>BassHub provides users with access to resources including news, memes, articles, and personalized quizzes related to bass guitar. The service may include advertisements necessary for operation.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">3. User Conduct</h2>
              <p>You agree to use the Site for lawful purposes only. Prohibited activities include posting material that is unlawful, threatening, abusive, defamatory, invasive of privacy, vulgar, obscene, or otherwise objectionable.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">4. Intellectual Property</h2>
              <p>The Site and its content are owned by BassHub and protected by intellectual property laws. You may not reproduce, duplicate, copy, sell, or exploit any portion of the Site without express written permission.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">5. Termination</h2>
              <p>We reserve the right to terminate or suspend access to the Site without prior notice for conduct that violates these Terms.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">6. Disclaimer of Warranties</h2>
              <p>The Site is provided "as is" without warranties of any kind. We do not guarantee the accuracy or completeness of the content.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">7. Limitation of Liability</h2>
              <p>BassHub shall not be liable for any damages arising from your use of the Site, including direct, indirect, incidental, or consequential damages.</p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold">8. Changes to Terms</h2>
              <p>We may modify these Terms of Service at any time. Changes will be posted on this page with an updated effective date. Your continued use of the Site constitutes acceptance of the revised terms.</p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};