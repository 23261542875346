import React from 'react';
import { ThumbsUp, ExternalLink, Clock } from 'lucide-react';
import { useStore } from '../store/useStore';
import type { CuratedContent } from '../types';

interface ContentCardProps {
  content: CuratedContent;
  onUpvote: () => void;
}

export const ContentCard: React.FC<ContentCardProps> = ({ content, onUpvote }) => {
  const { upvotes } = useStore();
  const upvoteCount = upvotes[content.id.toString()] || content.likes || 0;

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
  };

  return (
    <article className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
      <img 
        src={content.image} 
        alt={content.title}
        className="w-full h-48 object-cover"
      />
      
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-primary font-medium">
            {content.type === 'news' ? 'Bass News' : 'Trending Artist'}
          </span>
          <div className="flex items-center space-x-2 text-text-light">
            <Clock className="w-4 h-4" />
            <span>{content.date ? formatDate(content.date) : 'Recent'}</span>
          </div>
        </div>

        <h3 className="text-xl font-semibold mb-4">{content.title}</h3>
        
        {content.excerpt && (
          <p className="text-text-light mb-4 line-clamp-2">{content.excerpt}</p>
        )}

        <div className="flex items-center justify-between">
          <button
            onClick={onUpvote}
            className={`flex items-center space-x-2 px-3 py-1 rounded-full transition-colors ${
              upvoteCount > 0
                ? 'bg-primary/10 text-primary'
                : 'hover:bg-gray-100 text-text-light'
            }`}
          >
            <ThumbsUp className="w-4 h-4" />
            <span>{upvoteCount}</span>
          </button>

          <a
            href={content.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center space-x-2 text-primary hover:text-primary-dark transition-colors"
          >
            <span>Read More</span>
            <ExternalLink className="w-4 h-4" />
          </a>
        </div>
      </div>
    </article>
  );
};