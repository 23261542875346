import React from 'react';
import { Star, TrendingUp } from 'lucide-react';

interface Bassist {
  id: number;
  name: string;
  genre: string;
  image: string;
  trending: number;
  url: string;
  description: string;
}

const TRENDING_BASSISTS: Bassist[] = [
  {
    id: 1,
    name: "Marcus Miller",
    genre: "Jazz Fusion",
    image: "https://images.unsplash.com/photo-1511671782779-c97d3d27a1d4?auto=format&fit=crop&q=80",
    trending: 98,
    url: "https://www.marcusmiller.com",
    description: "Jazz fusion pioneer and multiple Grammy winner"
  },
  {
    id: 2,
    name: "Thundercat",
    genre: "Jazz Fusion / R&B",
    image: "https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?auto=format&fit=crop&q=80",
    trending: 95,
    url: "https://www.instagram.com/thundercatmusic",
    description: "Genre-defying virtuoso and collaborator"
  },
  {
    id: 3,
    name: "MonoNeon",
    genre: "Experimental / Funk",
    image: "https://images.unsplash.com/photo-1471478331149-c72f17e33c73?auto=format&fit=crop&q=80",
    trending: 93,
    url: "https://www.instagram.com/mononeon",
    description: "Innovative funk bassist known for unique style"
  },
  {
    id: 4,
    name: "Mohini Dey",
    genre: "Fusion / World",
    image: "https://images.unsplash.com/photo-1458639817867-2c9d4c5dcad4?auto=format&fit=crop&q=80",
    trending: 91,
    url: "https://www.instagram.com/mohinidey",
    description: "Prodigy bassist pushing technical boundaries"
  },
  {
    id: 5,
    name: "Joe Dart",
    genre: "Funk / Soul",
    image: "https://images.unsplash.com/photo-1459749411175-04bf5292ceea?auto=format&fit=crop&q=80",
    trending: 89,
    url: "https://www.vulfpeck.com",
    description: "Vulfpeck's groove master"
  }
];

export const TrendingBassists: React.FC = () => {
  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold text-text">Trending Bassists</h2>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {TRENDING_BASSISTS.map((bassist) => (
          <a 
            key={bassist.id} 
            href={bassist.url}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300"
          >
            <div className="relative">
              <img 
                src={bassist.image} 
                alt={bassist.name} 
                className="w-full h-48 object-cover"
              />
              <div className="absolute top-2 right-2 bg-secondary rounded-full px-3 py-1 flex items-center space-x-1">
                <TrendingUp className="w-4 h-4" />
                <span className="font-semibold">{bassist.trending}</span>
              </div>
            </div>
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-2">{bassist.name}</h3>
              <p className="text-text-light mb-2">{bassist.genre}</p>
              <p className="text-sm text-text-light">{bassist.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default TrendingBassists;