import React from 'react';
import { Guitar } from 'lucide-react';

export const Footer: React.FC = () => {
  return (
    <footer className="bg-white shadow-md mt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Guitar className="h-6 w-6 text-primary" />
              <span className="text-lg font-bold text-primary">BassHub</span>
            </div>
            <p className="text-text-light">
              Your ultimate destination for all things bass guitar.
            </p>
          </div>
          
          <div>
            <h3 className="font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2 text-text-light">
              <li><a href="#" className="hover:text-primary">News</a></li>
              <li><a href="#" className="hover:text-primary">Trending</a></li>
              <li><a href="#" className="hover:text-primary">Gear</a></li>
              <li><a href="#" className="hover:text-primary">Bass Quiz</a></li>
            </ul>
          </div>
          
          <div>
            <h3 className="font-semibold mb-4">Legal</h3>
            <ul className="space-y-2 text-text-light">
              <li><a href="#" className="hover:text-primary">Privacy Policy</a></li>
              <li><a href="#" className="hover:text-primary">Terms of Service</a></li>
              <li><a href="#" className="hover:text-primary">Affiliate Disclosure</a></li>
            </ul>
          </div>
        </div>
        
        <div className="border-t border-gray-200 mt-8 pt-8 text-center text-text-light">
          <p>&copy; {new Date().getFullYear()} BassHub. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};