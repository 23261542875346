import React from 'react';
import { Helmet } from 'react-helmet-async';

export const AffiliateDisclosure: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Affiliate Disclosure - BassHub</title>
        <meta name="description" content="BassHub's Affiliate Disclosure - Learn about our affiliate relationships and how we earn commissions." />
      </Helmet>
      
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="prose prose-lg">
          <h1 className="text-4xl font-bold mb-4">Affiliate Disclosure</h1>
          
          <p className="text-sm text-gray-600 mb-6">Effective Date: November 11, 2024</p>

          <div className="space-y-6">
            <p>BassHub participates in affiliate marketing programs, which means we may earn commissions from qualifying purchases made through links on our Site. These commissions help support our operations and allow us to provide quality content.</p>

            <p>We only promote products and services that we believe will add value to our users. Our opinions are our own, and we strive to provide honest reviews and recommendations.</p>

            <p>Please note that clicking on affiliate links does not result in any additional cost to you.</p>
          </div>
        </div>
      </div>
    </>
  );
};