import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface EngagementState {
  upvotes: Record<string, number>;
  viewedContent: string[];
  addUpvote: (contentId: string) => void;
  removeUpvote: (contentId: string) => void;
  addViewedContent: (contentId: string) => void;
}

export const useStore = create<EngagementState>()(
  persist(
    (set) => ({
      upvotes: {},
      viewedContent: [],
      addUpvote: (contentId) =>
        set((state) => ({
          upvotes: {
            ...state.upvotes,
            [contentId]: (state.upvotes[contentId] || 0) + 1,
          },
        })),
      removeUpvote: (contentId) =>
        set((state) => ({
          upvotes: {
            ...state.upvotes,
            [contentId]: Math.max((state.upvotes[contentId] || 0) - 1, 0),
          },
        })),
      addViewedContent: (contentId) =>
        set((state) => ({
          viewedContent: [...new Set([...state.viewedContent, contentId])],
        })),
    }),
    {
      name: 'bass-hub-storage',
    }
  )
);