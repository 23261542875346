import React from 'react';
import { Tag, ShoppingBag } from 'lucide-react';
import { generateAffiliateLink, trackAffiliateClick } from '../utils/affiliate';

interface GearDeal {
  id: number;
  title: string;
  price: number;
  originalPrice: number;
  image: string;
  discount: number;
  url: string;
  brand: string;
}

export const GearDeals: React.FC = () => {
  const deals: GearDeal[] = [
    {
      id: 1,
      title: "Fender Player Plus Jazz Bass",
      price: 1099.99,
      originalPrice: 1299.99,
      image: "https://images.unsplash.com/photo-1609178016676-dfbdbdeaddbb?auto=format&fit=crop&q=80",
      discount: 15,
      url: "https://www.sweetwater.com/store/detail/JBassPlus--fender-player-plus-jazz-bass",
      brand: "Fender"
    },
    {
      id: 2,
      title: "Darkglass Microtubes B7K Ultra v2",
      price: 329.99,
      originalPrice: 399.99,
      image: "https://images.unsplash.com/photo-1545167496-c1e092d383a2?auto=format&fit=crop&q=80",
      discount: 18,
      url: "https://www.thomann.de/darkglass_microtubes_b7k_ultra_v2.htm",
      brand: "Darkglass"
    },
    {
      id: 3,
      title: "Markbass Little Mark Tube 800",
      price: 799.99,
      originalPrice: 899.99,
      image: "https://images.unsplash.com/photo-1546058256-47154de4046c?auto=format&fit=crop&q=80",
      discount: 12,
      url: "https://www.sweetwater.com/store/detail/LittleMarkTube--markbass-little-mark-tube-800-bass-amp-head",
      brand: "Markbass"
    }
  ];

  const handleDealClick = (deal: GearDeal) => {
    trackAffiliateClick(deal.url);
    window.open(generateAffiliateLink(deal.url), '_blank');
  };

  return (
    <div className="space-y-8">
      <div className="flex justify-between items-center">
        <h2 className="text-3xl font-bold text-text">Hot Deals</h2>
        <span className="text-sm text-text-light">Affiliate links support BassHub</span>
      </div>
      
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {deals.map((deal) => (
          <div key={deal.id} className="bg-white rounded-xl shadow-md overflow-hidden hover:shadow-lg transition-all duration-300">
            <div className="relative">
              <img 
                src={deal.image} 
                alt={deal.title} 
                className="w-full h-48 object-cover"
                onError={(e) => {
                  e.currentTarget.src = "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?auto=format&fit=crop&q=80";
                }}
              />
              <div className="absolute top-2 right-2 bg-secondary rounded-full px-3 py-1 flex items-center space-x-1">
                <Tag className="w-4 h-4" />
                <span className="font-semibold">-{deal.discount}%</span>
              </div>
              <div className="absolute bottom-2 left-2 bg-black/50 rounded-full px-3 py-1">
                <span className="text-white text-sm font-medium">{deal.brand}</span>
              </div>
            </div>
            
            <div className="p-6">
              <h3 className="text-xl font-semibold mb-4">{deal.title}</h3>
              <div className="flex items-center justify-between">
                <div>
                  <span className="text-primary font-bold text-xl">${deal.price}</span>
                  <span className="ml-2 text-text-light line-through">${deal.originalPrice}</span>
                </div>
                <button
                  onClick={() => handleDealClick(deal)}
                  className="bg-primary hover:bg-primary-dark text-white px-6 py-2 rounded-lg transition-colors flex items-center space-x-2"
                >
                  <span>Buy</span>
                  <ShoppingBag className="w-5 h-5" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};