import React from 'react';
import { useStore } from '../store/useStore';
import { ContentCard } from './ContentCard';
import { Helmet } from 'react-helmet-async';

const NEWS_ITEMS = [
  {
    id: 1,
    title: "Fender Unveils 2024 American Professional III Precision Bass",
    excerpt: "Fender's latest P-Bass features upgraded pickups, enhanced ergonomics, and a revolutionary new bridge design for improved sustain and tone shaping.",
    image: "https://images.unsplash.com/photo-1516924962500-2b4b3b99ea02?auto=format&fit=crop&q=80",
    date: "2024-03-15T10:00:00Z",
    likes: 156,
    url: "https://www.fender.com/articles/gear/american-professional-III-precision-bass",
    type: 'news' as const
  },
  {
    id: 2,
    title: "The 10 Most Influential Bass Lines That Changed Music History",
    excerpt: "From James Jamerson's 'What's Going On' to John Paul Jones' 'Good Times Bad Times', we explore the bass lines that revolutionized popular music.",
    image: "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?auto=format&fit=crop&q=80",
    date: "2024-03-14T15:30:00Z",
    likes: 324,
    url: "https://www.bassplayer.com/lessons/most-influential-basslines",
    type: 'news' as const
  }
];

export const NewsFeed: React.FC = () => {
  const { addUpvote } = useStore();

  return (
    <>
      <Helmet>
        <title>Latest Bass News - BassHub</title>
        <meta name="description" content="Stay up to date with the latest bass guitar news, reviews, and industry updates. Get the freshest content from the bass guitar world." />
        <meta name="keywords" content="bass news, bass guitar news, bass player news, bass gear news, bass industry updates" />
      </Helmet>

      <div className="space-y-8">
        <h2 className="text-3xl font-bold text-text">Latest Bass News</h2>
        <div className="grid gap-6 md:grid-cols-2">
          {NEWS_ITEMS.map((item) => (
            <ContentCard 
              key={item.id}
              content={item}
              onUpvote={() => addUpvote(item.id.toString())}
            />
          ))}
        </div>
      </div>
    </>
  );
};