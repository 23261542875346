import React, { useState } from 'react';
import { Music, ChevronRight, RefreshCcw, Trophy } from 'lucide-react';

export const BassQuiz: React.FC = () => {
  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState<string[]>([]);

  const questions = [
    {
      question: "What's your preferred bass playing style?",
      options: ["Slap & Pop", "Fingerstyle", "Pick", "Tapping"]
    },
    {
      question: "Which genre resonates with you the most?",
      options: ["Funk/R&B", "Jazz/Fusion", "Rock/Metal", "Pop/Session"]
    },
    {
      question: "How do you approach bass solos?",
      options: ["Melodic", "Technical", "Groove-based", "I don't solo"]
    },
    {
      question: "What's your ideal band role?",
      options: ["Groove Master", "Lead Bass", "Support Player", "Band Leader"]
    }
  ];

  const bassistProfiles = [
    {
      name: "Flea",
      description: "Your energetic style and funk-driven approach matches the RHCP legend!",
      image: "https://images.unsplash.com/photo-1511735111819-9a3f7709049c?auto=format&fit=crop&q=80",
      styles: ["Slap & Pop", "Funk/R&B", "Technical", "Lead Bass"]
    },
    {
      name: "James Jamerson",
      description: "Your smooth, melodic style and rock-solid groove channels the Motown master!",
      image: "https://images.unsplash.com/photo-1493225457124-a3eb161ffa5f?auto=format&fit=crop&q=80",
      styles: ["Fingerstyle", "Funk/R&B", "Groove-based", "Groove Master"]
    },
    {
      name: "Jaco Pastorius",
      description: "Your innovative approach and technical prowess mirrors the jazz fusion pioneer!",
      image: "https://images.unsplash.com/photo-1471478331149-c72f17e33c73?auto=format&fit=crop&q=80",
      styles: ["Fingerstyle", "Jazz/Fusion", "Melodic", "Lead Bass"]
    },
    {
      name: "John Entwistle",
      description: "Your powerful playing and technical precision matches The Who's thunderfingers!",
      image: "https://images.unsplash.com/photo-1470225620780-dba8ba36b745?auto=format&fit=crop&q=80",
      styles: ["Pick", "Rock/Metal", "Technical", "Band Leader"]
    }
  ];

  const handleAnswer = (answer: string) => {
    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);

    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      setStep(questions.length);
    }
  };

  const resetQuiz = () => {
    setStep(0);
    setAnswers([]);
  };

  const getResult = () => {
    let bestMatch = bassistProfiles[0];
    let maxMatches = 0;
    
    bassistProfiles.forEach(profile => {
      const matches = answers.filter(answer => profile.styles.includes(answer)).length;
      if (matches > maxMatches) {
        maxMatches = matches;
        bestMatch = profile;
      }
    });
    
    return bestMatch;
  };

  return (
    <div className="max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold mb-8 text-primary">
        Which Famous Bassist Are You?
      </h2>

      <div className="bg-white rounded-xl shadow-md p-8">
        {step < questions.length ? (
          <>
            <div className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg font-semibold text-text">
                  Question {step + 1} of {questions.length}
                </span>
                <span className="text-primary">
                  {Math.round(((step + 1) / questions.length) * 100)}%
                </span>
              </div>
              <div className="w-full bg-gray-100 rounded-full h-2">
                <div 
                  className="bg-primary rounded-full h-2 transition-all duration-300"
                  style={{ width: `${((step + 1) / questions.length) * 100}%` }}
                />
              </div>
            </div>

            <h3 className="text-xl text-text mb-6">{questions[step].question}</h3>
            
            <div className="grid grid-cols-2 gap-4">
              {questions[step].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswer(option)}
                  className="p-4 text-left rounded-lg bg-gray-50 hover:bg-primary hover:text-white border border-gray-200 hover:border-primary transition-colors flex items-center justify-between group"
                >
                  <span>{option}</span>
                  <ChevronRight className="h-4 w-4 opacity-0 group-hover:opacity-100" />
                </button>
              ))}
            </div>
          </>
        ) : (
          <div className="text-center">
            <div className="relative w-48 h-48 mx-auto mb-6 rounded-full overflow-hidden">
              <img 
                src={getResult().image}
                alt={getResult().name}
                className="w-full h-full object-cover"
              />
            </div>
            
            <h3 className="text-2xl font-bold mb-2 text-text">
              You are most like {getResult().name}!
            </h3>
            <p className="text-text-light mb-8">{getResult().description}</p>
            
            <button
              onClick={resetQuiz}
              className="inline-flex items-center space-x-2 px-6 py-3 rounded-lg bg-primary text-white hover:bg-primary-dark transition-colors"
            >
              <RefreshCcw className="h-4 w-4" />
              <span>Take Quiz Again</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};