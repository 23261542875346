import React, { useState } from 'react';
import { Music, ChevronRight, RefreshCcw, Trophy } from 'lucide-react';

const QuizWidget = () => {
  const [step, setStep] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [answers, setAnswers] = useState<string[]>([]);

  const questions = [
    {
      question: "What's your preferred bass playing style?",
      options: ["Slap & Pop", "Fingerstyle", "Pick", "Tapping"]
    },
    {
      question: "Which genre speaks to your soul?",
      options: ["Funk/R&B", "Jazz/Fusion", "Rock/Metal", "Pop/Session"]
    },
    {
      question: "How do you approach the groove?",
      options: ["Complex & Technical", "Simple & Solid", "Melodic & Flowing", "Heavy & Aggressive"]
    },
    {
      question: "What's your ideal band role?",
      options: ["Groove Master", "Lead Bass", "Support Player", "Band Leader"]
    }
  ];

  const bassistProfiles = [
    {
      name: "Flea",
      description: "Your energetic style and funk-driven approach matches the RHCP legend!",
      styles: ["Slap & Pop", "Funk/R&B", "Complex & Technical", "Lead Bass"]
    },
    {
      name: "James Jamerson",
      description: "Your smooth, melodic style and rock-solid groove channels the Motown master!",
      styles: ["Fingerstyle", "Funk/R&B", "Simple & Solid", "Groove Master"]
    },
    {
      name: "Jaco Pastorius",
      description: "Your innovative approach and technical prowess mirrors the jazz fusion pioneer!",
      styles: ["Fingerstyle", "Jazz/Fusion", "Melodic & Flowing", "Lead Bass"]
    },
    {
      name: "John Entwistle",
      description: "Your powerful playing and technical precision matches The Who's thunderfingers!",
      styles: ["Pick", "Rock/Metal", "Complex & Technical", "Band Leader"]
    }
  ];

  const handleNext = (answer: string) => {
    setIsAnimating(true);
    const newAnswers = [...answers, answer];
    setAnswers(newAnswers);
    
    setTimeout(() => {
      if (step < questions.length - 1) {
        setStep(step + 1);
      } else {
        setStep(questions.length); // Show result
      }
      setIsAnimating(false);
    }, 300);
  };

  const handleReset = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setStep(0);
      setAnswers([]);
      setIsAnimating(false);
    }, 300);
  };

  const getResult = () => {
    let bestMatch = bassistProfiles[0];
    let maxMatches = 0;
    
    bassistProfiles.forEach(profile => {
      const matches = answers.filter(answer => profile.styles.includes(answer)).length;
      if (matches > maxMatches) {
        maxMatches = matches;
        bestMatch = profile;
      }
    });
    
    return bestMatch;
  };

  return (
    <div className="relative overflow-hidden rounded-2xl bg-white shadow-md p-6">
      <div className="absolute top-0 left-0 w-full h-1 bg-gray-100">
        <div
          className="h-full bg-primary transition-all duration-300"
          style={{ width: `${((step + 1) / (questions.length + 1)) * 100}%` }}
        />
      </div>

      <div className={`transform transition-all duration-300 ${
        isAnimating ? 'opacity-0 translate-x-4' : 'opacity-100 translate-x-0'
      }`}>
        {step < questions.length ? (
          <>
            <div className="flex items-center space-x-2 mb-4">
              <Music className="h-5 w-5 text-primary" />
              <span className="text-sm font-medium text-primary">
                Question {step + 1} of {questions.length}
              </span>
            </div>

            <h3 className="text-xl font-semibold text-text mb-6">{questions[step].question}</h3>

            <div className="grid grid-cols-2 gap-3">
              {questions[step].options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleNext(option)}
                  className="p-3 text-sm rounded-lg bg-gray-50 hover:bg-primary hover:text-white border border-gray-200 hover:border-primary transition-all duration-200 flex items-center justify-between group"
                >
                  <span>{option}</span>
                  <ChevronRight className="h-4 w-4 opacity-0 group-hover:opacity-100 transition-opacity" />
                </button>
              ))}
            </div>
          </>
        ) : (
          <div className="text-center py-4">
            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-primary/10 mb-4">
              <Trophy className="h-8 w-8 text-primary" />
            </div>
            <h3 className="text-xl font-semibold mb-2 text-text">You're {getResult().name}!</h3>
            <p className="text-text-light mb-6">
              {getResult().description}
            </p>
            <button
              onClick={handleReset}
              className="inline-flex items-center space-x-2 px-4 py-2 rounded-lg bg-primary text-white hover:bg-primary-dark transition-all duration-200"
            >
              <RefreshCcw className="h-4 w-4" />
              <span>Try Again</span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizWidget;